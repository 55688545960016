import axios from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  AccountMetadata,
  AccountMap,
  AccountMetadataInfo,
  AccountNameSearchRuntimeMatch,
  AccountNameSearchRuntimeResults,
} from './named-accounts'
import { Layer, useGetRuntimeAccountsAddresses } from '../../oasis-nexus/api'
import { Network } from '../../types/network'
import { hasTextMatch } from '../components/HighlightedText/text-matching'
import { getOasisAddress } from '../utils/helpers'

// Only use DeltaDaoRegistry as the data source.
const DATA_SOURCES: { url: string; source: 'DeltaDaoRegistry' }[] = [
  {
    url: 'https://raw.githubusercontent.com/deltaDAO/mvg-portal/main/pontusxAddresses.json',
    source: 'DeltaDaoRegistry',
  },
  {
    url: 'https://raw.githubusercontent.com/oceanByte/explorer-test/refs/heads/main/pontusXContracts.json',
    source: 'DeltaDaoRegistry',
  },
]

type PontusXAccountsMetadata = {
  map: AccountMap
  list: AccountMetadata[]
}

export const pontusXLayers: Layer[] = [Layer.pontusxtest, Layer.pontusxdev, Layer.sapphire]

const getPontusXAccountsMetadata = async (): Promise<PontusXAccountsMetadata> => {
  // Fetch the data source in parallel (in case more sources are added later)
  const responses = await Promise.all(
    DATA_SOURCES.map(({ url, source }) => axios.get(url).then(res => ({ res, source }))),
  )

  // Check each response for errors or missing data
  responses.forEach(({ res, source }) => {
    if (res.status !== 200) throw new Error(`Couldn't load data from ${source}`)
    if (!res.data) throw new Error(`No data returned from ${source}`)
  })

  const map: AccountMap = new Map()
  const list: AccountMetadata[] = []

  // Process the data source
  responses.forEach(({ res, source }) => {
    Object.entries(res.data).forEach(([evmAddress, name]) => {
      const account: AccountMetadata = {
        source,
        address: getOasisAddress(evmAddress),
        name: name as string,
      }
      map.set(evmAddress.toLowerCase(), account)
      list.push(account)
    })
  })

  return { map, list }
}

export const usePontusXAccountsMetadata = (
  queryOptions: UseQueryOptions<PontusXAccountsMetadata, unknown, PontusXAccountsMetadata, string[]>,
) => {
  return useQuery(['pontusXNames'], getPontusXAccountsMetadata, {
    staleTime: Infinity,
    ...queryOptions,
  })
}

export const usePontusXAccountMetadata = (
  address: string,
  queryOptions: UseQueryOptions<PontusXAccountsMetadata, unknown, PontusXAccountsMetadata, string[]>,
): AccountMetadataInfo => {
  const { isLoading, isError, error, data: allData } = usePontusXAccountsMetadata(queryOptions)
  if (isError) {
    console.log('Failed to load Pontus-X account names', error)
  }
  return {
    metadata: allData?.map.get(address.toLowerCase()),
    isLoading,
    isError,
  }
}

export const useSearchForPontusXAccountsByName = (
  network: Network,
  nameFragment: string,
  queryOptions: { enabled: boolean } & UseQueryOptions<
    PontusXAccountsMetadata,
    unknown,
    PontusXAccountsMetadata,
    string[]
  >,
): AccountNameSearchRuntimeResults => {
  const {
    isLoading: isMetadataLoading,
    isError: isMetadataError,
    error: metadataError,
    data: namedAccounts,
  } = usePontusXAccountsMetadata(queryOptions)
  if (isMetadataError) {
    console.log('Failed to load Pontus-X account names', metadataError)
  }

  const textMatcher =
    nameFragment && queryOptions.enabled
      ? (account: AccountMetadata) => hasTextMatch(account.name, [nameFragment])
      : () => false

  const matches = isMetadataLoading
    ? undefined
    : namedAccounts?.list.filter(textMatcher).map(
        (account): AccountNameSearchRuntimeMatch => ({
          network,
          layer: Layer.pontusxtest,
          address: account.address,
        }),
      )

  const {
    isLoading: areAccountsLoading,
    isError: areAccountsError,
    data: results,
  } = useGetRuntimeAccountsAddresses(matches, {
    enabled: queryOptions.enabled && !isMetadataLoading && !isMetadataError,
  })

  return {
    isLoading: isMetadataLoading || areAccountsLoading,
    isError: isMetadataError || areAccountsError,
    results,
  }
}
